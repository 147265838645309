import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang, RawPreviousTrip } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site'
import translations from '../../entities/translations';
import PageSection from '../../components/organism/HomePageComponent';
import {Card} from '../../components/molecule/Card'
import { buildImageFocalPoint, formatMoney } from '../../utils';
import { countrySubMenu } from '../../utils/subNavigation';
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country,
    subNavigationItem: countrySubMenu,
    subNavigationTitle: undefined
  })
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const [previousTrips, setPreviousTrip] = useState<RawPreviousTrip [] | undefined>(undefined)
  const { state } = useGlobalStateContext();
  const buttonTransaltion = translations.globals.buttons;
  const pageID = translations.globals.subMenuTranslation;
  const pageHash = translations.countryMenuID;
  const FROM = translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];

  useEffect(() => {
    if (page?.previousTrips?.length) {
      const _previousTrips = [...page?.previousTrips]
      if (_previousTrips.length < 3) {
        setPreviousTrip(_previousTrips)
      } else {
        _previousTrips.length = 3
        setPreviousTrip(_previousTrips)
      }
    }
  }, [page?.previousTrips])
  
  const handleReadMore = () => {
    setPreviousTrip(page?.previousTrips)
    setShowReadMore(false)
  }

  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="py-12" id={pageHash?.Inspirationen?.[state?.lang as lang]!}>
      {page?.previousTripIntro?.[state?.lang as lang]
        && <PortableTextRenderer body={page?.previousTripIntro?.[state?.lang as lang]} />}
      </Wrapper>

      <PageSection
        readMoreLink={"#"}  
        sanityContext={null}
        showReadMore={showReadMore}
        id={``}
        idTitle={pageID.Inspirationen[state?.lang as lang]!}
        onClick={handleReadMore}
        title={''} >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
         {previousTrips?.map((item, i: number) => <Card
         uniqueRef={item?.['_id']!}
          key={i}
          _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
          imageAlt={(item.tripImage?.alt && item?.tripImage?.alt[state?.lang as lang]) || ''}
          imageUrl={buildImageFocalPoint(item.tripImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang]) || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="PACKAGES"
          bodyText={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
          duration={(item?.daysAndNightsShort && item.daysAndNightsShort[state?.lang as lang]) || ''}
          price={`${FROM} ${(item?.priceCategoryFrom && formatMoney(item.priceCategoryFrom, item.priceCategoryCurrency))}` || ''}
          cardSize={'SMALL'}
          /> )}
        </div>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryPreviousTripPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryPreviousTripsImage(resolveReferences: {maxDepth: 2})
      previousTripIntro: _rawPreviousTripsIntro(resolveReferences: {maxDepth: 4})
      previousTrips :_rawPreviousTrips(resolveReferences: {maxDepth: 5})
      metadata: _rawCountryPreviousTripsMetadata
    }
  }
`
