import React from 'react';
import PortableText from './PortableText';
import Text from "../../components/atoms/Text";

interface IProp {
  body: any, 
  className?: string 
  pClassName?: string
  normalClassName?: string
  blockquoteClassName?: string
}

const PortableTextRenderer: React.FC<IProp> = ({ 
  body, 
  className='lg:w-8/12', 
  pClassName ='',
  normalClassName ='text-dove-gray',
  blockquoteClassName ='',
}) => {
  return <PortableText content={body}
  className={className}
  template={{
    h2: (props: any) => <Text type="H2" className="mb-4">{props.children}</Text>,
    normal: (props: any) => <Text type="P" ptype="BASE" 
      className={`leading-7 ${normalClassName}`}>{props.children}</Text>,
    blockquote: (props: any) => <Text type="Quote" ptype="BASE" className={blockquoteClassName} >{props.children}</Text>,
    p: (props: any) => <Text type="P" ptype="BASE" className={pClassName}>{props.children}</Text>,
    ul: (props: any) => <ul className="text-sm m-0 list-inside list-disc" >{props.children}</ul>,
    li: (props: any) => <li className="text-sm text-dove-gray" >{props.children}</li>,
  }} />
}

export default PortableTextRenderer;
