import { useEffect } from 'react'

import { CountryPagePropsType, RegionPagePropsType, 
  AccommodationPagePropsType, TitleType, TripPagePropsType , lang, PageContext } from '../../entities/queriesTypes'
  import { SubNavItem  } from '../../entities/pagepart'
import { useGlobalStateContext, ACTION_TYPES } from '../../Context/site'
import { CurrentView } from '../../entities/templates';
import { regionSubMenu } from '../../utils/subNavigation'

type ParentView = 'country' | 'subviews';

interface ArgsInterface {
  page: CountryPagePropsType['data']['pageData'] | 
  RegionPagePropsType['data']['pageData'] |
   AccommodationPagePropsType['data']['pageData'] | TripPagePropsType['data']['pageData'] ;
  locale: CountryPagePropsType['pageContext']['locale'],
  currentView: CurrentView,
  pageContext:  PageContext,
  parentView?: ParentView,
  subNavigationItem?: SubNavItem[]
  subNavigationTitle?: {
    [key in lang]: string
  },
  useSubviewsAsMenu?: boolean
}

const useSetGlobalState = ({ 
  page, locale, currentView, pageContext, parentView = 'country',
  subNavigationItem, subNavigationTitle, useSubviewsAsMenu
 }: ArgsInterface) => {
  const { stateDispatch } = useGlobalStateContext();

  useEffect(() => {
    const handleSetArrayOfLocale = () => {
      const values = Object.keys(page?.title as TitleType<string>);
      const _locales = (values.filter(item => item !== '_type') as unknown) as lang[];

      _locales.length &&
      stateDispatch &&
      stateDispatch({ 
        type: ACTION_TYPES.SET_ALL_LOCALES,
        key: 'allLocales',
        payload: _locales
      });
    }

    if(stateDispatch) {
      //setLang 
      locale && stateDispatch({ 
        type: ACTION_TYPES.SET_LANG,
        key: 'lang', 
        payload: locale
      });

      stateDispatch({ 
        type: ACTION_TYPES.SET_IS_PAGE_PART_VIEW,
        key: 'pagePartView', 
        payload: false
      });


      //subNavigationItem
      subNavigationItem?.length && stateDispatch({
        type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_LIST,
        key: 'subNavigationItem',
        payload: subNavigationItem 
      });

      //subNavigationTitle
      if(parentView === 'subviews') {
        page?.parentCountry?.title && stateDispatch({ 
          type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_TITLE,
          key: 'subNavigationTitle',
          payload: page?.parentCountry?.title
        });

        if(regionSubMenu(page?.title!) && useSubviewsAsMenu ) {
          page?.title && stateDispatch({
            type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_LIST,
            key: 'subNavigationItem',
            payload: regionSubMenu(page?.title!)!
          });
        }
      } else {
        page?.title && stateDispatch({
          type: ACTION_TYPES.SET_PAGE_SUBNAVIGATION_TITLE,
          key: 'subNavigationTitle',
          payload: page?.title 
        });
      }

      //setCurrentView
      currentView && stateDispatch({ 
        type: ACTION_TYPES.SET_CURRENT_VIEW,
        key: 'currentView',
        payload: currentView
      });

      //setCountry
      if (parentView === 'subviews') {
        page?.parentCountry?.title && stateDispatch({ 
          type: ACTION_TYPES.SET_COUNTRY,
          key: 'countryName',
          payload: page?.parentCountry?.title[locale!]
        });
      } else {
        page?.title && stateDispatch({ 
          type: ACTION_TYPES.SET_COUNTRY,
          key: 'countryName',
          payload: page?.title[locale!]
        });

        page?.title && stateDispatch({ 
          type: ACTION_TYPES.SET_HERO_IMAGE_TITLE,
          key: 'heroImageTitle',
          payload: page?.title[locale!]
        });
      }

      //setAllSlugs
      pageContext?.allSlugs && stateDispatch({ 
        type: ACTION_TYPES.SET_ALL_SLUGS,
        key: 'allSlugs',
        payload: pageContext?.allSlugs
      });

      //setAllSlugs
      pageContext?.siteConfig && stateDispatch({ 
        type: ACTION_TYPES.SET_SITE_CONFIG,
        key: 'siteConfig',
        payload: pageContext?.siteConfig
      });

      //setSubTitleData
      page?.subTitle && stateDispatch({ 
        type: ACTION_TYPES.SET_SUB_TITLE_DATA,
        key: 'subTitleData',
        payload: page?.subTitle[locale!]
      });

      //setLAngPageImage
      page?.heroImage && stateDispatch({ 
        type: ACTION_TYPES.SET_HERO_IMAGE_VIEW_IMAGE,
        key: 'heroImageViewImage',
        payload: page?.heroImage
      });
    }

    page?.title && handleSetArrayOfLocale();
  }, [
    locale, page?.subTitle, page?.title, 
    page?.heroImage, pageContext?.allSlugs, 
    stateDispatch, currentView, pageContext?.siteConfig, parentView, 
    page.parentView, page?.parentCountry?.title, subNavigationItem,
    subNavigationTitle, useSubviewsAsMenu
  ]);
}

export default useSetGlobalState;
